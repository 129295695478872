import React, {Fragment} from 'react'
import {Button, withStyles} from '@material-ui/core';
import styles from './styles'
import {withTranslation} from 'react-i18next';
import {compose} from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames'
import {Close as CloseIcon, Edit as EditIcon,} from '@material-ui/icons';
import 'moment-duration-format';
import {PortletContent, PortletHeader} from 'components';
import {AuthService} from 'services/auth/authService';
import {DateService} from 'services/util/DateService';
import {userPermissions} from 'services/auth/userPermissions';
import {displayAddress, displayDeliveryWeekDay, displayShipperAndShipperShortName} from 'services/util/helperFuncs';
import {getTemplateStopTypeString, TemplateStopType} from '../../../../services/enums/TemplateStopType';
import Paper from '@material-ui/core/Paper';

function TemplateStopDetailShow(props) {

  const {
    classes,
    className,
    templateStop,
    relatedTemplateStops,
    edit,
    t,
    readOnly,
    closeDetailView,
    selectTemplateStop
  } = props;

  const rootClassName = classNames(classes.root, className);

  return (
    <Fragment>
      <PortletHeader className={rootClassName}>
        <Fragment>
          <div><strong>{t('templateStopId')}: {templateStop.id}</strong></div>
          <div>
            {closeDetailView &&
              <Button
                className={classes.button}
                color="default"
                onClick={closeDetailView}
                title={t('close')}
                variant="contained"
              >
                <CloseIcon/>
              </Button>
            }
            {((AuthService.getUserPermission() !== userPermissions.user) && !readOnly) &&
              <Button
                className={classes.button}
                color="primary"
                onClick={edit}
                title={t('templateStopEdit')}
                variant="contained"
              >
                <EditIcon/>
              </Button>
            }
          </div>
        </Fragment>
      </PortletHeader>
      <PortletContent noPadding>
        <div className={classes.wrapper}>
          <div className={classes.wrapperChild}>
            <div className={classes.templateStopDetail}>
              <strong>{t('generalInformation')}:</strong>
              <div className={classes.templateStopDetail}>
                <strong>{t('stopType')}</strong>: {t(getTemplateStopTypeString(templateStop.stopType))}</div>
              <div className={classes.templateStopDetail}><strong>{t('shipper')}</strong>: {displayShipperAndShipperShortName(templateStop)}</div>
            </div>
            <br/>
            <div className={classes.templateStopDetail}><strong>{t('customerData')}:</strong>
              <div className={classes.templateStopDetail}><strong>{t('name')}</strong>: {templateStop.name}</div>
              <div className={classes.templateStopDetail}><strong>{t('internalCustomerId')}</strong>: {templateStop.internalCustomerId}</div>
              <div className={classes.templateStopDetail}><strong>{templateStop.stopType === TemplateStopType.Pickup ? t('shop') : t('chest')}</strong>: {templateStop.customerId}
              </div>
              <div className={classes.templateStopDetail}><strong>{t('address')}</strong>: {displayAddress(templateStop.address)}</div>
              <div className={classes.templateStopDetail}><strong>{t('customerInfo')}:</strong>
                <div
                  className={classes.templateStopDetail}>{templateStop.customerInfo ? templateStop.customerInfo : '-'}</div>
              </div>
              <div className={classes.templateStopDetail}>
                <strong>{t('deliveryWindow')}</strong>: {templateStop.deliveryWindow ? `${DateService.displayTime(templateStop?.deliveryWindow?.from)} - ${DateService.displayTime(templateStop?.deliveryWindow?.to)}` : '-'}
              </div>
              <div className={classes.templateStopDetail}><strong>{t('deliveryWeekDay')}</strong>: {displayDeliveryWeekDay(t, templateStop.deliveryWeekDay)}</div>
              <br/>
            </div>
          </div>
          <div className={classes.wrapperChild}>
            <div className={classes.templateStopDetail}>
              {
                templateStop.stopType === TemplateStopType.Pickup ?
                  <strong>{t('relatedDeliveryStops')}:</strong> :
                  <strong>{t('relatedPickupStops')}:</strong>
              }
              {
                relatedTemplateStops.map((relatedTemplateStop) =>
                  <Paper className={classNames(classes.templateStopDetail, classes.relatedTemplateStopWrapper)}
                         key={relatedTemplateStop.id} onClick={() => selectTemplateStop({id: relatedTemplateStop.id})}>
                    <div className={classes.templateStopDetail}>
                      <strong>{t('name')}</strong>: {relatedTemplateStop?.name}</div>
                    <div className={classes.templateStopDetail}>
                      <strong>{t('address')}</strong>: {displayAddress(relatedTemplateStop?.address)}</div>
                  </Paper>
                )
              }
            </div>
          </div>
        </div>
      </PortletContent>
    </Fragment>
  );
}


TemplateStopDetailShow.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  closeDetailView: PropTypes.func,
  edit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  relatedTemplateStops: PropTypes.array.isRequired,
  selectTemplateStop: PropTypes.func.isRequired,
  templateStop: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(withStyles(styles), withTranslation())(TemplateStopDetailShow);
